<template>
    <v-container>
        <v-card class="mb-10">
            <v-card-title class="card-title">Regulation Form</v-card-title>
        </v-card>
        <v-form :readonly="readOnly">
            <v-row align="center">
                <v-col cols="12">
                    Answer
                    <editor
                        :id="`regulation_content`"
                        v-model="regulation.content"
                        :api-key="tinymceApiKey"
                        :init="editorInit"
                    />
                </v-col>
            </v-row>
        </v-form>
        <div v-if="mode !== 'show'" class="submit-button-wrapper">
            <v-btn :loading="loading" class="mx-2 white--text" large color="black" @click="onSubmit">
                Submit
            </v-btn>
            <v-btn class="mx-2 white--text" large color="red" @click="$router.go(-1)">
                Cancel
            </v-btn>
        </div>
        <alert :show-alert="showAlert" :message="message" :type="type" />
    </v-container>
</template>

<script>
    import configService from '@/services/config'
    import Alert from '@/components/Alert'
    import Editor from '@tinymce/tinymce-vue'
    export default {
        name: 'RegulationForm',
        components: { Alert, Editor },
        data: () => {
            return {
                showAlert: false,
                message: null,
                type: null,
                mode: null,
                readOnly: false,
                clearable: true,
                loading: false,
                regulation: null,
                rules: [
                    v => !!v || 'Field is required'
                ],
                image: {
                    name: 'Image file'
                },
                imageChanged: false,
                imagePath: '',
                articleDateTime: null,
                editorInit: {
                    menubar: false,
                    min_height: 600,
                    plugins: [
                        'advlist autolink lists charmap print preview anchor',
                        'searchreplace visualblocks fullscreen',
                        'insertdatetime table paste code help wordcount link'
                    ],
                    toolbar: 'undo redo | styleselect | forecolor | bold italic | fontselect | emoticons | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent | link unlink'
                },
                tinymceApiKey: process.env.VUE_APP_TINYMCE_API_KEY
            }
        },
        async created() {
            this.mode = this.$route.params.mode
            if (this.mode === 'show') {
                this.readOnly = true
                this.clearable = false
            }
            configService.getConfigField('regulation').then(resp => {
                this.regulation = resp.data
            })
        },
        methods: {
            setAlert(message, type) {
                this.showAlert = true
                this.message = message
                this.type = type
                setTimeout(() => { this.showAlert = false }, 7000)
            },
            editRegulation() {
                configService.updateConfigField('regulation', this.regulation).then(resp => {
                    this.loading = false
                }).catch((error) => {
                    this.loading = false
                    this.setAlert(error.response.data.message, 'error')
                })
            },
            async onSubmit() {
                this.loading = true
                if (this.mode === 'edit') {
                    this.editRegulation()
                }
            }
        }
    }
</script>
<style>
.tox .tox-editor-header {
    z-index: 0 !important;
}
.tox .tox-statusbar {
    display: none !important;
}

.tox .tox-toolbar--scrolling {
    flex-wrap: wrap !important;
}
.tox.tox-tinymce.tox-platform-touch {
    height: 40vh !important;
    min-height: 350px;
}
</style>
<style scoped>
.body-label {
    color: rgba(0, 0, 0, 0.6);
}
.body-editor {
    margin-bottom: 2vh;
}

.submit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
}

</style>
