import api from '@/utils/api'

export default {
    updateConfigField(configField, data) {
        return api.put(`config/${configField}`, data)
    },

    getConfigField(configField) {
        return api.get(`config/${configField}`)
    },

    getConfig() {
        return api.get(`config`)
    }
}
